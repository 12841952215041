import { Link } from "gatsby";
import React from "react";

const Staffheader = ({
  image,
  mobImage,
  heading,
  link,
  linkText,
  para1 = "",
  para2 = "",
  addClass = "",
  backgroundMobCta = false,
}) => {
  return backgroundMobCta ? (
    <div className={`staffing-header-wrapper ${addClass}`}>
      <div className="desktop-wrapper">
        <div className="image-wrapper">
          <img
            src={image}
            alt="staffing services by Goken America, Goken India"
            className="desktop-wrapper"
          />
          <img
            src={mobImage ? mobImage : image}
            alt="staffing services by Goken America, Goken India"
            className="mobile-wrapper"
          />
        </div>
        <div className="content-wrapper">
          <h2 className="heading desktop-wrapper">{heading}</h2>
          {para1 && (
            <div className="para-wrapper text-center">
              <p className="para margin-0">{para1}</p>
              {para2 && <p className="para margin-0">{para2}</p>}
            </div>
          )}
          <button className="bottom-section-button para fw-700 wipe-btn wipe lft-rgt-wipe">
            <Link to={link} rel="noopener">
              {linkText}
            </Link>
          </button>
        </div>
      </div>
      <div
        className="mobile-wrapper"
        style={{ background: `url(${mobImage})`, backgroundSize: "cover" }}
      >
        <div className="content-wrapper">
          <h2 className="semi-heading">{heading}</h2>
          {para1 && (
            <div className="para-wrapper text-center">
              <p className="para margin-0">{para1}</p>
              {para2 && <p className="para margin-0">{para2}</p>}
            </div>
          )}
          <button className="bottom-section-button para fw-700 wipe-btn wipe lft-rgt-wipe">
            <Link to={link} rel="noopener">
              {linkText}
            </Link>
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className={`staffing-header-wrapper ${addClass}`}>
      <div className="image-wrapper">
        <img
          src={image}
          alt="staffing services by Goken America, Goken India"
          className="desktop-wrapper"
        />
        <img
          src={mobImage ? mobImage : image}
          alt="staffing services by Goken America, Goken India"
          className="mobile-wrapper"
        />
      </div>
      <div className="content-wrapper">
        <h1 className="heading desktop-wrapper">{heading}</h1>
        <h2 className="semi-heading mobile-wrapper">{heading}</h2>

        {para1 && (
          <div className="para-wrapper text-center">
            <p className="para margin-0">{para1}</p>
            {para2 && <p className="para margin-0">{para2}</p>}
          </div>
        )}
        <button className="bottom-section-button para fw-700 wipe-btn wipe lft-rgt-wipe">
          <Link to={link} rel="noopener">
            {linkText}
          </Link>
        </button>
      </div>
    </div>
  );
};

export default Staffheader;
