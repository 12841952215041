import React from "react";

const TalentManagement = ({ title, list = [], noborder = false }) => {
  return (
    <div className="staffing-talent-wrapper w-90-wrapper p-top-3rem">
      <h2 className="heading">{title}</h2>
      <div className="cards-wrapper">
        <div className="talent-cards-wrapper">
          {list?.map(({ title, para, image }) => (
            <div className="talent-card">
              <img src={image} alt="talent management image" />
              {noborder ? (
                <div className="content-wrapper-no-border">
                  <h2 className="para fw-700">{title}</h2>
                  <p className="para">{para}</p>
                </div>
              ) : (
                <div className="content-wrapper">
                  <h2 className="para fw-700">{title}</h2>
                  <p className="para">{para}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TalentManagement;
