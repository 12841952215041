import { Link } from "gatsby";
import React from "react";

const StaffingServices = ({
  title,
  list = [],
  para,
  ctaText,
  link,
  linkText,
  titleClass = "",
  pageClass = "",
  dotStyle = false,
}) => {
  return (
    <div className={`staffing-services-wrapper ${pageClass}`}>
      <div className="w-90-wrapper">
        <h2 className={`heading ${titleClass}`}>{title}</h2>
        {list.length == 6 &&
          (dotStyle ? (
            <div className="block-section-wrapper-dot-6">
              {list?.map((service) => (
                <div className="block-section-dot para">
                  <div className="dot-en"></div>
                  <h4 className="para">{service}</h4>
                </div>
              ))}
            </div>
          ) : (
            <div className="block-section-wrapper-6">
              {list?.map((service) => (
                <div className="block-section para">
                  <h4 className="para">{service}</h4>
                </div>
              ))}
            </div>
          ))}
        {list?.length === 8 && (
          <div className="block-section-wrapper">
            {list?.map((service) => (
              <div className="block-section para">
                <h4 className="para">{service}</h4>
              </div>
            ))}
          </div>
        )}
        <div className="info-wrapper">
          {para && <p className="para">{para}</p>}
          {ctaText && <p className="para color-en">{ctaText}</p>}
          {link && linkText && (
            <button className="btn reachout-btn en-btn">
              <Link to={link}>{linkText}</Link>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StaffingServices;
