import React from "react";

const HiringTech = ({ title, list }) => {
  return (
    <div className="hiring-method-wrapper">
      <div className="w-90-wrapper">
        <h2 className="text-center heading">{title}</h2>
        <div className="hirign-blocks-wrapper">
          {list?.map(({ number, title, methods }, index) => {
            return (
              <div
                className={`hiring-method-block ${
                  number === list.length ? "last-block" : ""
                }`}
              >
                <div className="number-wrapper">
                  <h3 className="title margin-0">{number}</h3>
                </div>
                <div className="content-wrapper">
                  <div className="header-wrapper">
                    <h4 className="semi-title text-center">{title}</h4>
                  </div>
                  <div className="content-wrapper">
                    <ul>
                      {methods?.map((li) => {
                        return <li className="para">{li}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HiringTech;
