import React, { useState } from "react";

const IndustryServe = ({ title, list = [], para }) => {
  const [active, setActive] = useState(0);
  console.log("Active is ", active);
  return (
    <div className="industry-serve-wrapper section-br">
      <div className="w-90-wrapper industry-serve-wrapper desktop-wrapper">
        <div className="left-industry-wrapper">
          <h2 className="heading">{title}</h2>
          <div className="industry-options-wrapper">
            {list.map(({ title }, index) => (
              <div
                key={`index-${title}`}
                className={`options-text ${
                  index === active ? "highlight" : "normal"
                }`}
                onMouseEnter={(e) => {
                  setActive(index);
                }}
                onMouseLeave={() => {
                  setActive(0);
                }}
              >
                <h3 className="title titlecase-text margin-0">
                  {title.toUpperCase()}
                </h3>
              </div>
            ))}
          </div>
        </div>
        <div className="right-industry-wrapper">
          <p className="para">{para}</p>
          {list?.map(({ para, image }, index) => (
            <div
              key={`${index}}`}
              className={`content-wrapper ${
                index == active ? "active" : "inactive"
              }`}
            >
              <div className="para-wrapper">
                <p className="para margin-0">{para}</p>
              </div>
              <img src={image} alt="staffing service for industry image" />
            </div>
          ))}
        </div>
      </div>
      <div className="industry-serve-mob-wrapper mobile-wrapper">
        <h3 className="heading w-90-wrapper">{title}</h3>
        <p className="para w-90-wrapper">{para}</p>
        <div className="industry-server-mob-section">
          {list.map(({ title, para, image }, index) => (
            <div key={`index-${title}`} className="industry-mob-card">
              <h4 className="title titlecase-text w-90-wrapper">{title}</h4>
              <div className="image-wrapper">
                <img src={image} alt="industry Goken serves image" />
                <div className="image-over-text para w-90-wrapper">{para}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IndustryServe;
