import { Link } from "gatsby";
import React from "react";

const PlainTextSection = ({
  title1,
  title2,
  paras,
  classSelector = "",
  bgclass = "",
  link = "",
  linkText,
  contentClass = "",
}) => {
  return (
    <div className={`plain-text-section ${bgclass}`}>
      <div className={`content-wrapper ${contentClass}`}>
        <h2 className={`${classSelector ? classSelector : "heading"} margin-0`}>
          {title1}
        </h2>
        {title2 && (
          <h3
            className={`${classSelector ? classSelector : "heading"} margin-0`}
          >
            {title2}
          </h3>
        )}
        {paras.map((para) => (
          <p className="para">{para}</p>
        ))}
        {link && (
          <div className="cta-section">
            <button className="btn-en reachout-btn">
              <Link to={link}>{linkText}</Link>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlainTextSection;
